
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcElementId:136,
            //             phoneElementId: 140,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Charging Pile',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 2
            //                 }, {
            //                     descName: 'IP66',
            //                     sort: 3
            //                 }, {
            //                     descName: '-25~+60°C',
            //                     sort: 4
            //                 }, {
            //                     descName: '5%~95%RH',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: 'Explosion-proof Grade',
            //                     sort: 6
            //                 }, {
            //                     descName: 'Protection Level',
            //                     sort: 7
            //                 }, {
            //                     descName: 'Working Temperature ',
            //                     sort: 8
            //                 }, {
            //                     descName: 'Ambient Temperature',
            //                     sort: 9
            //                 }],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcElementId: 137,
            //             phoneElementId: 141,
            //             descList: [ {
            //                     descName: 'It is suitable for the charging use of inspection robots in explosion-proof areas such as petroleum, chemical industry, storage and port, and has the functions of input overvoltage protection, input undervoltage protection, module overtemperature protection, input overcurrent protection, efficiency abnormal protection and abnormal detachment protection.',
            //                     sort: 1
            //                 },],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcElementId: 138,
            //             phoneElementId:142,
            //             descList: [
            //                 {
            //                     descName: 'Charging protection function',
            //                     sort: 1
            //                 }, {
            //                     descName: 'It has functions of overvoltage, overcurrent, over discharge, over temperature, under voltage, abnormal efficiency, surge and lightning protection, and abnormal disconnection protection.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcElementId: 139,
            //             phoneElementId: 143,
            //             descList: [
            //                 {
            //                     descName: 'Intelligent charging',
            //                     sort: 1
            //                 }, {
            //                     descName: 'The charging pile will cooperate with the robot to achieve intelligent charging function. After the robot returns to the designated position, it will automatically charge without manual intervention. After charging is completed, the charging will be automatically disconnected.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         }
            //     ],

            elmentList: [],
        }
    },
    created() {
        // this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
        });
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        openPdf() {
             this.$router.push('/pdfView')
        },

    }
}