<template>
  <!-- 防爆充电桩，英文版 -->
  <div class="around chargingStation" v-if="elmentList.length">
    <div class="banner_video"  :id="'elm' + elmentList[0].id">
      <img :src="elmentList[0].pcImg" v-if="this.$store.state.pageset.pcshow" />
      <img :src="elmentList[0].phoneImg" v-else />
      <div class="img-con">
        <div class="title">
          <h1>{{ elmentList[0].descList[0].descName }}</h1>
        </div>
      </div>
      <div class="dec" v-animate="'queue-bottom'">
        <div class="dec-par">
          <div class="dec-par-item" v-for="index in 4" :key="index">
            <p class="dec-par-item-title">
              {{ elmentList[0].descList[index].descName }}
            </p>
            <p class="dec-par-item-dec">
              {{ elmentList[0].descList[index + 4].descName }}
            </p>
          </div>
        </div>
        <div class="scroll-wrap">
          <div class="remark">
            The products appearing in the video on this page are for reference only. The appearance, configuration, model, and other details of the products may vary. Please refer to the actual local delivery for details.
          </div>
        </div>
      </div>
    </div>
    <div class="chargingStation-content">
      <div class="content-one" v-for="index in 3" :key="index"  :id="'elm' + elmentList[index].id">
        <img :src="elmentList[index].pcImg" v-if="$store.state.pageset.pcshow" />
        <img :src="elmentList[index].phoneImg" v-else />
        <div class="dec" v-animate="'queue-bottom'">
          <div class="dec-left" v-if="index === 1">
            <p class="p2">
              {{ elmentList[index].descList[0].descName }}
            </p>
          </div>
          <div class="dec-left" v-else>
            <p class="p1">
              {{ elmentList[index].descList[0].descName }}
            </p>
            <p class="p2">
              {{ elmentList[index].descList[1].descName }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else v-loading="true"></div>
</template>
<script>
import chargingStation_en from "./chargingStation_en.js";

export default chargingStation_en;
</script>
<style scoped rel="stylesheet/stylus" lang="scss">
@import "~@/assets/style/common.scss";
@import "../chargingStation.scss";
@import "../../common.scss";
</style>
